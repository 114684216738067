import withData from "../../lib/apollo"
import { gql } from "apollo-boost"
import { useQuery } from "@apollo/react-hooks"
import { Flex } from "../../Components/Flex"
import { Layout } from "../../Components/Layout"
import { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { Box, Spacer } from "../../Components/"
import { withRouter } from "next/router"
import { randomNumber } from "../../helpers/randomNumber"
import React from "react"
import { Media } from "../../Components/Responsive"

interface DesktopAssetProp {
  projectAsset: any
}

const HOME_QUERY = gql`
  query GetHome($limit: Int!, $offset: Int!) {
    allAbout {
      aboutText
    }
    allProject(sort: { name: ASC }) {
      _id
      name
      slug {
        current
      }
    }
    allProjectAsset(
      sort: { _createdAt: DESC }
      limit: $limit
      offset: $offset
      where: { displayOnHomepage: { eq: true } }
    ) {
      _id
      alt
      assetProject {
        _id
        name
      }
      video {
        asset {
          _id
          url
          mimeType
        }
      }
      image {
        asset {
          _id
          url
          metadata {
            _key
            dimensions {
              _key
              aspectRatio
            }
          }
        }
      }
    }
  }
`

const DesktopAsset = React.memo(({ projectAsset }: DesktopAssetProp) => {
  const wrapperRef = useRef(null)
  const videoRefMobile = useRef(null)
  const mobileWrapperRef = useRef(null)
  const imageSRC = projectAsset?.image?.asset?.url || ""
  const imageAspectRatio = projectAsset?.image?.asset?.metadata?.dimensions?.aspectRatio
  const resizedSRC = imageAspectRatio > 1 ? imageSRC + `?w=2000` : imageSRC + `?w=1500`

  const videoURL = projectAsset?.video?.asset?.url

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          videoRefMobile.current?.play()
        } else {
          videoRefMobile.current?.pause()
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.7,
      }
    )
    if (videoRefMobile?.current) {
      observer.observe(videoRefMobile?.current)
    }
    return () => {
      observer.disconnect()
    }
  }, [videoRefMobile])

  const firstRandom = randomNumber(-45, 45)
  const secondRandom = randomNumber(-45, 45)

  const onClickMobile = (e) => {
    e.stopPropagation()
    const random1 = randomNumber(-45, 45)
    const random2 = randomNumber(-45, 45)
    mobileWrapperRef.current.style.transform = `rotateX(${random1}deg) rotateY(${random2}deg)`
  }

  const onClickDesktop = (e) => {
    e.stopPropagation()
    const random1 = randomNumber(-45, 45)
    const random2 = randomNumber(-45, 45)
    wrapperRef.current.style.transform = `rotateX(${random1}deg) rotateY(${random2}deg)`
  }

  return (
    <>
      <Media greaterThan="sm">
        <Wrapper height={["60vh", "60vh", "70vh", "70vh", "70vh"]}>
          <span
            ref={wrapperRef}
            style={{
              position: "absolute",
              zIndex: 1,
              transform: `rotateX(${firstRandom}deg) rotateY(${secondRandom}deg)`,
              width: "100%",
              overflow: "hidden",
              display: "block",
              transition: "transform .8s ease-in-out",
            }}
          >
            {!!videoURL ? (
              <video
                style={{ width: "100%" }}
                loop
                muted
                autoPlay
                playsInline
                src={videoURL + "#t=1"}
                onClick={(e) => {
                  onClickDesktop(e)
                }}
              />
            ) : (
              <img
                src={resizedSRC}
                style={{ height: "100%", width: "100%", objectFit: "contain", maxHeight: "70vh" }}
                onClick={(e) => {
                  onClickDesktop(e)
                }}
              />
            )}
          </span>
        </Wrapper>
      </Media>
      <Media lessThan="md">
        <Wrapper height={["60vh", "60vh", "70vh", "70vh", "70vh"]}>
          <span
            ref={mobileWrapperRef}
            style={{
              position: "absolute",
              zIndex: 1,
              transform: `rotateX(${firstRandom}deg) rotateY(${secondRandom}deg)`,
              width: "100%",
              overflow: "hidden",
              display: "block",
              transition: "transform .8s ease-in-out",
            }}
          >
            {!!videoURL ? (
              <video
                style={{ width: "100%" }}
                loop
                playsInline
                preload="auto"
                muted
                src={videoURL + "#t=1"}
                ref={videoRefMobile}
                onClick={(e) => {
                  onClickMobile(e)
                }}
              />
            ) : (
              <img
                src={imageSRC + `?w=1000`}
                style={{ height: "100%", width: "100%", objectFit: "contain", maxHeight: "70vh" }}
                onClick={(e) => {
                  onClickMobile(e)
                }}
              />
            )}
          </span>
        </Wrapper>
      </Media>
    </>
  )
})

const Home = withData(({ router }) => {
  const containerRef = useRef(null)
  const [navMaxWidth, setNavMaxWidth] = useState(null)
  const { data, fetchMore, loading } = useQuery(HOME_QUERY, {
    variables: {
      limit: 10,
      offset: 0,
    },
  })

  const projects = data?.allProject

  const onFetchMore = () => {
    fetchMore({
      variables: {
        offset: data?.allProjectAsset?.length,
      },
      updateQuery: (prev: any, { fetchMoreResult }) => {
        if (!prev) {
          return []
        }

        if (!fetchMoreResult) {
          return prev
        }

        const firstMoreId = fetchMoreResult?.allProjectAsset?.[0]?._id
        const includes = !!prev.allProjectAsset.find((proj) => proj._id === firstMoreId)
        if (includes) {
          return prev
        }

        return Object.assign({}, prev, {
          allProjectAsset: [...prev.allProjectAsset, ...fetchMoreResult.allProjectAsset],
        })
      },
    })
  }

  useEffect(() => {
    const onScroll = () => {
      if (
        !loading &&
        containerRef?.current.scrollTop + window.innerHeight >= containerRef?.current?.scrollHeight - 500
      ) {
        onFetchMore()
      }
    }
    if (typeof window !== "undefined") {
      containerRef?.current.addEventListener("scroll", onScroll)
    }
    return () => containerRef?.current.removeEventListener("scroll", onScroll)
  }, [data, onFetchMore, containerRef])

  useEffect(() => {
    const onResize = () => {
      if (containerRef?.current) {
        setNavMaxWidth(containerRef?.current.clientWidth)
      }
    }

    onResize()

    if (typeof window !== "undefined") {
      window.addEventListener("resize", onResize)
    }
    return () => window.removeEventListener("resize", onResize)
  }, [containerRef])

  return (
    <Layout projects={projects} maxWidth={navMaxWidth} router={router} aboutText={data?.allAbout?.[0]?.aboutText}>
      <Container ref={containerRef}>
        <Spacer mb={100} />
        {data?.allProjectAsset.map((projectAsset) => {
          return (
            <Flex width="100%" key={projectAsset._id + "mobile"} flexDirection="column" py={[50, 50, 300, 300, 300]}>
              <DesktopAsset projectAsset={projectAsset} />
            </Flex>
          )
        })}
      </Container>
    </Layout>
  )
})

const Wrapper = styled(Flex)`
  position: relative;
  justify-content: center;
  width: 100%;
`

const Container = styled(Box)`
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  perspective: 250px;
  overflow-x: hidden;
`

export default withRouter(Home)
